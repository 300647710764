<template>
  <div class="register-container">
    <div class="register-header shadow-border-bottom">
      <img src="@/assets/images/logo.png" alt="">
      <el-button
        plain round size="meduim"
        style="padding: 8px 35px;"
        @click="$router.push({name:'login'})">
        {{ $t('loginImmediately') }}
      </el-button>
    </div>

    <div class="register-body">
      <h1 class="big-title">
        <span>Welcome to Bunkerchain</span>
        <em class="close-icon el-icon-close" @click="handleCancelRegisterCompany()"></em>
      </h1>
      <h2  class="small-title">{{ $t('enterpriseRegister') }}</h2>
      <div class="form">
        <!--企业信息填写-->
        <el-form
          ref="companyInfoForm"
          :rules="companyInfoRules"
          :model="companyInfoFormData"
          :validate-on-rule-change="false"
          label-position="top">
          <div class="form-block">
            <div class="title">{{ $t('companyInfo') }}</div>
            <el-row :gutter="10">
              <el-col :sm="6" :md="8" :lg="3" >
                <el-form-item
                  label="Physical Supplier"
                  prop="licenseType">
                  <div style="padding:0 12px;">
                    <el-switch
                      v-model="companyInfoFormData.licenseType"
                      @change="handleLicenseTypeChange"
                      active-color="#8182ff"
                      active-text="Yes"
                      inactive-text="No">
                    </el-switch>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :sm="6" :md="8" :lg="3">
                <el-form-item
                  label="License No."
                  prop="licenseNumber">
                  <el-input
                    v-model="companyInfoFormData.licenseNumber"
                    :disabled="!companyInfoFormData.licenseType"
                    class="input-with-select">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item
                  :label="$t('companyName')"
                  prop="companyName">
                  <el-input
                    v-model="companyInfoFormData.companyName"
                    @change="handleCompanyNameChange">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item
                  label="Company Abbreviation(3 to 6 letters)"
                  prop="abbreviation">
                  <el-input v-uppercase:true v-model="companyInfoFormData.abbreviation"></el-input>
                </el-form-item>
              </el-col>
            <!-- </el-row>
            <el-row :gutter="10"> -->
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item
                  :label="$t('businessIncorporationNumber')"
                  prop="businessIncorporationNumber">
                  <el-input
                    v-model="companyInfoFormData.businessIncorporationNumber"
                    @change="handleCompanyUENChange">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="6" :md="8" :lg="3">
                <el-form-item
                  label="GST Registration No."
                  prop="gstRegistrationNo">
                  <el-input v-model="companyInfoFormData.gstRegistrationNo"></el-input>
                </el-form-item>
              </el-col>
              <el-col  :sm="6" :md="8" :lg="3" >
                <el-form-item
                  :label="$t('incorporationDate')"
                  prop="incorporationDate">
                  <el-date-picker
                    style="width: 100%"
                    v-model="companyInfoFormData.incorporationDate"
                    type="date"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd 00:00:01"
                    :placeholder="$t('selectDate')">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="6" :md="8" :lg="3" >
                <el-form-item
                  :label="$t('companyType')"
                  prop="companyType">
                  <el-select
                    style="width: 100%"
                    v-model="companyInfoFormData.companyType">
                    <el-option
                      v-for="item in companyOptions"
                      :key="item.key"
                      :label="item.value"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="6" :md="8" :lg="3" >
                <el-form-item
                  :label="$t('incorporationCountry')"
                  prop="incorporationCountry">
                  <country-selector
                    style="width: 100%"
                    v-model="companyInfoFormData.incorporationCountry">
                  </country-selector>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4" >
                <el-form-item
                  :label="$t('streetName')"
                  prop="streetName">
                  <el-input v-model="companyInfoFormData.streetName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :md="8" :lg="4">
                <el-form-item
                  :label="$t('buildingName')"
                  prop="buildingName">
                  <el-input v-model="companyInfoFormData.buildingName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="6" :md="8"  :lg="4" >
                <el-form-item
                  :label="$t('unitNumber')"
                  prop="unitNumber">
                  <el-input v-model="companyInfoFormData.unitNumber"></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="6" :md="8"  :lg="3" >
                <el-form-item
                  :label="$t('postal')"
                  prop="postal">
                  <el-input v-model="companyInfoFormData.postal"></el-input>
                </el-form-item>
              </el-col>

              <el-col  :sm="12" :md="8"  :lg="3">
                <el-form-item
                  :label="$t('fax')"
                  prop="fax">
                  <el-input v-model="companyInfoFormData.fax"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!--管理员信息填写-->
          <el-form
            ref="adminInfoForm"
            :rules="adminInfoRules"
            :model="adminInfoFormData"
            label-position="top">
            <div class="form-block">
              <div class="title">{{ $t('adminInfo') }}</div>
              <el-row :gutter="10">
                <el-col  :sm="12" :md="8" :lg="6"  >
                  <el-form-item
                    :label="$t('email')"
                    prop="email">
                    <div class="double-column">
                      <el-input
                        :disabled="emailVerificationPassed || !!uuid"
                        v-model="adminInfoFormData.email">
                      </el-input>
                      <el-button
                        style="margin-left: 10px; border:0;"
                        round
                        type="primary"
                        plain
                        :disabled="emailVerificationPassed"
                        :loading="loading"
                        @click="getVerificationCode">
                        {{ $t(emailVerificationPassed ? 'emailVerified' : 'clickVerifyEmail') }}
                      </el-button>
                    </div>
                  </el-form-item>
                </el-col>
                <template v-if="!this.emailExist">
                  <el-col :sm="12" :md="8" :lg="6"  >
                    <el-form-item :label="$t('firstName')" prop="firstName">
                      <el-input :disabled="!emailVerificationPassed" v-model="adminInfoFormData.firstName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8" :lg="6"  >
                    <el-form-item :label="$t('lastName')" prop="lastName">
                      <el-input :disabled="!emailVerificationPassed" v-model="adminInfoFormData.lastName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="6" :md="8"  :lg="3"  >
                    <el-form-item :label="$t('password')" prop="password">
                      <el-input :disabled="!emailVerificationPassed" v-model="adminInfoFormData.password"
                                show-password>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="6" :md="8"  :lg="3">
                    <el-form-item :label="$t('duplicatePassword')" prop="duplicatePassword">
                      <el-input :disabled="!emailVerificationPassed" v-model="adminInfoFormData.duplicatePassword"
                                show-password>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8" :lg="6">
                    <el-form-item :label="$t('homePhoneNumber')" prop="homePhoneNumber">
                      <phone-input :disabled="!emailVerificationPassed"
                                   v-model="adminInfoFormData.homePhoneNumber"></phone-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8" :lg="6">
                    <el-form-item :label="$t('officePhoneNumber')" prop="officePhoneNumber">
                      <phone-input :disabled="!emailVerificationPassed"
                                   v-model="adminInfoFormData.officePhoneNumber"></phone-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8" :lg="6">
                    <el-form-item :label="$t('designation')" prop="designation">
                      <el-input :disabled="!emailVerificationPassed" v-model="adminInfoFormData.designation"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8" :lg="6"  >
                    <el-form-item :label="$t('salutation')" prop="salutation">
                      <el-select
                        style="width: 100%"
                        :disabled="!emailVerificationPassed"
                        v-model="adminInfoFormData.salutation">
                        <el-option
                          v-for="item in ['Mr.','Mrs.','Miss','Dr.','Ms.','Prof.','Rev.','Lady.','Sir.','Capt.']"
                          :key="item"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
              </template>
              </el-row>
            </div>
          </el-form>
          <!-- financer info -->
          <financer-register
            v-if="companyInfoFormData.companyType === COMPANY_TYPE.FINANCIAL_SERVICE_PROVIDER"
            ref="financerInfo"/>
          <!--文件上传-->
          <div class="form-block document-upload">
            <div class="title">{{ $t('companyDocument') }}</div>
            <el-row :gutter="10">
              <el-col
                  :sm="12" :md="8"
                v-for="item in fileFields.slice(0, 3)"
                :key="item">
                <el-form-item
                  :label="$t(item)"
                  :prop="item">
                  <div class="upload-column">
                    <el-upload
                      :action="fileUploadUrl"
                      :accept="item==='bdnLogo'?'.jpg,.png':'.pdf'"
                      :limit="1"
                      :file-list="companyInfoFormData[item]"
                      :on-success="handleFileUploadSuccess(item,'single')"
                      :before-remove="handleBeforeRemove"
                      :on-remove="handleRemove(item)">
                      <el-button
                        class="upload-btn"
                        :type="companyInfoFormData[item].length?'danger':'primary'"
                        :disabled="!!companyInfoFormData[item].length"
                        plain round>
                        {{
                          companyInfoFormData[item].length ? $t('uploaded') : item === 'bdnLogo' ? $t('uploadImage') : $t('uploadFile')
                        }}
                      </el-button>
                      <div
                        slot="tip"
                        class="el-upload__tip">
                        {{
                          companyInfoFormData[item].length ? $t('uploadedAndCanDelete') : item === 'bdnLogo' ? $t('uploadAttachmentPic') : $t('uploadAttachmentPDF')
                        }}
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
              <el-col
                  :sm="12" :md="8"
                v-for="item in fileFields.slice(3, 5)"
                :key="item">
                <el-form-item
                  :label="$t(item)"
                  :prop="item">
                  <div class="upload-column">
                    <el-upload
                      :action="fileUploadUrl"
                      :accept="item==='bdnLogo'?'.jpg,.png':'.pdf'"
                      :limit="1"
                      :file-list="companyInfoFormData[item]"
                      :on-success="handleFileUploadSuccess(item,'single')"
                      :before-remove="handleBeforeRemove"
                      :on-remove="handleRemove(item)">
                      <el-button
                        class="upload-btn"
                        :type="companyInfoFormData[item].length?'danger':'primary'"
                        :disabled="!!companyInfoFormData[item].length"
                        plain round>
                        {{
                          companyInfoFormData[item].length ? $t('uploaded') : item === 'bdnLogo' ? $t('uploadImage') : $t('uploadFile')
                        }}
                      </el-button>
                      <div
                        slot="tip"
                        class="el-upload__tip">
                        {{
                          companyInfoFormData[item].length ? $t('uploadedAndCanDelete') : item === 'bdnLogo' ? $t('uploadAttachmentPic') : $t('uploadAttachmentPDF')
                        }}
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
              <!-- <el-col  :sm="12" :md="8">
                <el-form-item
                  :label="$t('otherDocument')"
                  prop="otherDocument">
                  <div class="upload-column">
                    <el-upload
                      :action="fileUploadUrl"
                      accept=".pdf,.jpg,.png"
                      :file-list="companyInfoFormData.otherDocument"
                      :on-success="handleFileUploadSuccess('otherDocument','multiple')"
                      :before-remove="handleBeforeRemove"
                      :on-remove="handleRemove('otherDocument')"
                      multiple>
                      <el-button
                       class="upload-btn"
                        type="primary"
                        plain
                        round>
                        {{ $t('uploadFile') }}
                      </el-button>
                      <div slot="tip"  class="el-upload__tip">
                        {{ $t('uploadAttachmentPDF') }}
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col> -->
            </el-row>
            <!-- <el-row :gutter="10">
              <el-col
                :span="12"
                v-for="item in fileFields.slice(4, 5)"
                :key="item">
                <el-form-item
                  :label="item"
                  :prop="item">
                  <div class="upload-column">
                    <el-upload
                      :action="fileUploadUrl"
                      :accept="item==='bdnLogo'?'.jpg,.png':'.pdf'"
                      :limit="1"
                      :file-list="companyInfoFormData[item]"
                      :on-success="handleFileUploadSuccess(item,'single')"
                      :before-remove="handleBeforeRemove"
                      :on-remove="handleRemove(item)">
                      <el-button
                        class="upload-btn"
                        :type="companyInfoFormData[item].length?'danger':'primary'"
                        :disabled="!!companyInfoFormData[item].length"
                        style="border:0; font-size:17px; width:200px;"
                        plain round>
                        {{
                          companyInfoFormData[item].length ? $t('uploaded') : item === 'bdnLogo' ? $t('uploadImage') : $t('uploadFile')
                        }}
                      </el-button>
                      <div
                        slot="tip"
                        style="font-size: 13px; white-space: nowrap;"
                        class="el-upload__tip">
                        {{
                          companyInfoFormData[item].length ? $t('uploadedAndCanDelete') : item === 'bdnLogo' ? $t('uploadAttachmentPic') : $t('uploadAttachmentPDF')
                        }}
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="otherDocument"
                  prop="otherDocument">
                  <div class="upload-column">
                    <el-upload
                      :action="fileUploadUrl"
                      accept=".pdf,.jpg,.png"
                      :file-list="companyInfoFormData.otherDocument"
                      :on-success="handleFileUploadSuccess('otherDocument','multiple')"
                      :before-remove="handleBeforeRemove"
                      :on-remove="handleRemove('otherDocument')"
                      multiple>
                      <el-button
                        type="primary"
                        style="border:0; font-size:17px; width:200px;"
                        plain
                        round>
                        {{ $t('uploadFile') }}
                      </el-button>
                      <div slot="tip" style="font-size: 13px; white-space: nowrap;" class="el-upload__tip">{{ $t('uploadAttachmentPDF') }}</div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
            </el-row> -->
            <!-- <el-form-item
              v-for="item in fileFields.filter(item=>item!=='otherDocument')"
              :key="item"
              :label="$t(item)"
              :prop="item">
              <el-upload
                :action="fileUploadUrl"
                :accept="item==='bdnLogo'?'.jpg,.png':'.pdf'"
                :limit="1"
                :file-list="companyInfoFormData[item]"
                :on-success="handleFileUploadSuccess(item,'single')"
                :before-remove="handleBeforeRemove"
                :on-remove="handleRemove(item)">
                <el-button
                  :type="companyInfoFormData[item].length?'danger':'primary'"
                  :disabled="!!companyInfoFormData[item].length"
                  plain
                  >
                  {{
                    companyInfoFormData[item].length ? $t('uploaded') : item === 'bdnLogo' ? $t('uploadImage') : $t('uploadFile')
                  }}
                </el-button>
                <div
                  slot="tip"
                  class="el-upload__tip">
                  {{
                    companyInfoFormData[item].length ? $t('uploadedAndCanDelete') : item === 'bdnLogo' ? $t('uploadAttachmentPic') : $t('uploadAttachmentPDF')
                  }}
                </div>
              </el-upload>
            </el-form-item> -->
          </div>
          <!-- <div class="double-column">
            <el-button
              style="width: 50%"
              @click="reset(1)">
              {{ $t('reset') }}
            </el-button>
            <el-button
              style="width: 50%"
              type="primary"
              @click="switchStep(2)">
              {{ $t('next') }}
            </el-button>
          </div> -->
        </el-form>
      </div>
      <!--语言切换-->
      <!-- <div class="language">
        <span
          class="cn"
          :class="{active:$i18n.locale==='cn'}"
          @click="handleSetLanguage('cn')">
          中文
        </span>
        <span
          class="en"
          :class="{active:$i18n.locale==='en'}"
          @click="handleSetLanguage('en')">
          English
        </span>
      </div> -->
    </div>
    <div class="register-foot">
      <div class="check-bar">
        <el-checkbox class="checkbox" v-model="agreeProtocol"></el-checkbox>
        <span style="color: #606266">
            I have read and agree
        </span>
        <span>
          <a href="/documents/terms-of-use.pdf" target="_blank">Terms of Use</a>
        </span>
        &
        <span>
          <a href="/documents/privacy-policy.pdf" target="_blank">Privacy Policy</a>
        </span>
        <!-- <span
          class="protocol"
          @click="protocolVisible=true"> -->
          <!-- 《{{ $t('registrationAgreement') }}》 -->
          <!-- &lt;Corporate Registration Agreement of Bunkerchain&gt;
        </span> -->
      </div>
      <div class="btn-group">
        <el-button
          type="primary"
          round
          :loading="submitLoading"
          :disabled="!emailVerificationPassed || !agreeProtocol"
          @click="register">
          {{ $t('register') }}
        </el-button>
        <el-button
          type="primary"
          round
          plain
          @click="$router.push({name:'login'})">
          Close
        </el-button>
      </div>
    </div>

    <!--邮箱校验弹出层-->
    <el-dialog
      :visible.sync="codeVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="30%">
      <div style="padding: 20px">
        <p>
          <em class="el-icon-info"></em>
          {{ `${$t('verifyTipFront')} ${adminInfoFormData.email} ${$t('verifyTipEnd')}` }}
        </p>
        <el-input
          v-model="verificationCode"
          :placeholder="$t('verificationCode')">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          size="small"
          :loading="loading"
          @click="cancelVerifyEmail">
          {{ $t('cancel') }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          :loading="loading"
          @click="verifyEmailCode">
          {{ $t('verify') }}
        </el-button>
      </span>
    </el-dialog>

    <!--企业注册协议弹出层-->
    <el-dialog
      class="el-dialog-company-agreement"
      :visible.sync="protocolVisible"
      fullscreen
      width="30%">
      <div style="padding: 10px 30px 30px">
        <h2 style="text-align: center">Corporate Registration Agreement of Bunkerchain</h2>
        <P>........</P>
      </div>
    </el-dialog>

    <!-- 注册成功 -->
    <el-dialog
      title=""
      :visible.sync="registerSuccessVisible"
      width="660px"
      top="25vh"
      :close-on-click-modal="false"
      @close="$router.push({name:'login'})"
      center>
      <div class="register-success-text">Your registration has been submitted and is pending for approval.</div>
      <div class="register-success-text">Please check your registered email for the approval outcome.</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain round @click="registerSuccessVisible = false">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import CountrySelector from '@/components/CountrySelector'
import FinancerRegister from './FinancerRegister.vue'
import { COMPANY_TYPE } from '@/constants.js'

export default {
  name: 'EnterpriseRegister',
  mixins: [baseMixin],
  components: { CountrySelector, FinancerRegister },
  data () {
    // 企业信息必需字段
    const companyInfoRequiredFields = [
      'licenseNumber',
      'businessIncorporationNumber',
      'companyName',
      'abbreviation',
      'incorporationDate',
      'incorporationCountry',
      'streetName',
      // 'buildingName',
      // 'unitNumber',
      'postal',
      'companyType',
      'contactPerson',
      'email',
      'phone',
      'designation',
      'businessIncorporationDocument',
      'bdnLogo',
      'licenseType',
      'gstRegistrationNo',
    ]
    // 企业信息非必需字段
    const companyInfoNotRequiredFields = [
      'fax',
      'authorizationLetter',
      'operatorLicences',
      'craftOperatorLicences',
      'otherDocument',
      'buildingName',
      'unitNumber'
    ]
    // 文件字段
    const fileFields = [
      'businessIncorporationDocument',
      'craftOperatorLicences',
      'authorizationLetter',
      'bdnLogo',
      'operatorLicences', // 'MPA Bunker Licences'
      'otherDocument'
    ]
    const companyInfoFormData = {}
    let fields = [...companyInfoRequiredFields, ...companyInfoNotRequiredFields]
    fields.forEach(field => {
      if (fileFields.includes(field)) {
        companyInfoFormData[field] = []
      } else {
        companyInfoFormData[field] = null
      }
      if (field === 'companyType') {
        companyInfoFormData[field] = COMPANY_TYPE.TRADING
      }
      if (field === 'licenseType') {
        companyInfoFormData[field] = true
      }
    })
    // 管理员信息必需字段
    const adminInfoRequiredFields = [
      'email',
      'password',
      'duplicatePassword',
      'firstName',
      'lastName',
      'password',
      'homePhoneNumber'
    ]
    // 管理员信息非必需字段
    const adminInfoNotRequiredFields = [
      'salutation',
      'officePhoneNumber',
      'designation'
    ]
    const adminInfoFormData = {}
    fields = [...adminInfoRequiredFields, ...adminInfoNotRequiredFields]
    fields.forEach(field => {
      adminInfoFormData[field] = null
    })
    // 企业信息校验规则
    const companyInfoRules = {}
    // 管理员信息校验规则
    const adminInfoRules = {}
    companyInfoRequiredFields.forEach(field => {
      companyInfoRules[field] = [
        { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
      ]
      if (field === 'email') {
        companyInfoRules[field].push({
          type: 'email',
          required: true,
          message: this.$t('emailFormatError'),
          trigger: 'blur'
        })
      }
      if (field === 'abbreviation') {
        companyInfoRules[field].push({
          message: 'Only be three to six letters',
          pattern: /^[A-Z]{3,6}$/,
          trigger: 'blur'
        })
      }
    })
    adminInfoRequiredFields.forEach(field => {
      adminInfoRules[field] = [
        { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
      ]
      if (field === 'email') {
        companyInfoRules[field].push({
          type: 'email',
          required: true,
          message: this.$t('emailFormatError'),
          trigger: 'blur'
        })
      }
    })
    adminInfoRules.password = [
      { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' },
      {
        pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(-)_+=~`]).{8,30})/,
        message: 'Password at least one number, uppercase, lowercase, special characters, 8 to 30 bits in length',
        trigger: 'blur'
      }
    ]
    const validateDuplicatePassword = (rule, value, callback) => {
      if (value !== this.adminInfoFormData.password) {
        return callback(new Error(this.$t('pswNotMatch')))
      }
      return callback()
    }
    adminInfoRules.duplicatePassword = [
      { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' },
      { validator: validateDuplicatePassword, trigger: 'blur' }
    ]
    return {
      companyInfoFormData,
      companyInfoRules,
      adminInfoFormData,
      adminInfoRules,
      fileFields,
      companyInfoRequiredFields,
      companyInfoNotRequiredFields,
      uuid: '',
      stepActive: 1,
      fileUploadUrl: '',
      // 手机号的国际码
      mobileState: '+65',
      // 同意协议
      agreeProtocol: false,
      // 邮箱验证码
      verificationCode: '',
      verificationId: null,
      // 邮箱校验弹窗可见
      codeVisible: false,
      // 协议弹窗可见
      protocolVisible: false,
      loading: false,
      // 邮箱校验通过
      emailVerificationPassed: false,
      submitLoading: false,
      // 管理员邮箱是否存在
      emailExist: false,
      registerSuccessVisible: false,
      COMPANY_TYPE
    }
  },
  computed: {
    companyOptions () {
      return Object.entries(this.COMPANY_TYPE).map(([key, value]) => ({key,value}))
    }
  },
  created () {
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadFile
    const hash = location.hash
    if (hash.includes('uuid')) {
      // hash中包括uuid,则是重新修改注册信息，获取uuid
      const list = hash.split('uuid=')
      this.uuid = list[list.length - 1]
      this.$request.post({
        url: this.$apis.getCompanyInfoByUuid,
        data: {
          uuid: this.uuid
        }
      }).then(res => {
        if (res?.code === 1000) {
          const { organization: company, user } = res.data
          if (company) {
            Object.keys(this.companyInfoFormData).forEach(key => {
              if (this.fileFields.includes(key)) {
                const value = this.$_.cloneDeep(company[key]) || []
                value.forEach(item => {
                  item.url = item.path
                  // 修改文件名称为原始文件名称，以便显示原始文件名称
                  item.name = item.originalName || item.name
                })
                this.companyInfoFormData[key] = value
              } else {
                this.companyInfoFormData[key] = company[key]
              }
            })
            this.companyInfoFormData.id = company.id
            Object.keys(this.adminInfoFormData).forEach(key => {
              this.adminInfoFormData[key] = user[key]
            })
            this.adminInfoFormData.id = user.id
          } else {
            this.adminInfoFormData.email = res.data.email
          }
        }
      })
      this.emailVerificationPassed = true
      // this.emailExist = true
    }
  },
  methods: {
    /**
     *切换语言
     */
    handleSetLanguage (lang) {
      this.$i18n.locale = lang
      localStorage.language = lang
    },

    /**
     *重置数据
     */
    reset (step) {
      step === 1 && this.$refs.companyInfoForm.resetFields()
      step === 2 && !this.$refs.adminInfoForm.resetFields() && (this.agreeProtocol = false)
    },

    /**
     * 校验重复的email
     */
    validateDuplicateEmail (reqData) {
      return new Promise((resolve, reject) => {
        this.validateDuplicateData(reqData).then(data => {
          if (data) {
            // 存在相同邮箱的账号
            this.emailExist = true
            this.$confirm(this.$t('duplicateEmail'), this.$t('duplicateWarning'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
              showClose: false,
              type: 'warning'
            }).then(() => {
              resolve()
            }).catch(() => {
              this.emailExist = false
              reject(new Error())
            })
          } else {
            this.emailExist = false
            resolve()
          }
        })
      })
    },

    /**
     *获取邮箱验证码
     */
    getVerificationCode () {
      this.verificationCode = ''
      if (!this.adminInfoFormData.email) {
        this.$message.error(this.$t('inputEmail'))
        return
      }
      const emailObj = {
        userEmail: this.adminInfoFormData.email
      }
      this.validateDuplicateEmail(emailObj).then(() => {
        this.loading = true
        this.$request.post({
          url: this.$apis.sendEmail,
          data: {
            email: this.adminInfoFormData.email
          }
        }).then(res => {
          if (res?.code === 1000) {
            this.verificationId = res.data.id
            this.codeVisible = true
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.adminInfoFormData.email = ''
      })
    },

    /**
     * 取消校验邮箱
     */
    cancelVerifyEmail () {
      this.emailExist = false
      this.codeVisible = false
    },

    /**
     * 校验邮箱有效性
     */
    verifyEmailCode () {
      if (!this.verificationCode) {
        this.$message.error(this.$t('inputCode'))
        return
      }
      this.$request.post({
        url: this.$apis.verifyEmail,
        data: {
          email: this.adminInfoFormData.email,
          code: this.verificationCode,
          id: this.verificationId
        }
      }).then(data => {
        if (data?.code === 1000) {
          this.$message.success(this.$t('emailPassed'))
          this.emailVerificationPassed = true
          this.codeVisible = false
        } else {
          this.$message.error(this.$t('emailFailed'))
        }
      })
    },

    handleRemove (field) {
      /**
       * 文件移除之后更新相应字段值
       */
      return (file, fileList) => {
        this.companyInfoFormData[field] = fileList
      }
    },

    /**
     * 调用接口删除文件
     * @param {String} file:字段名
     */
    handleBeforeRemove (file) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.deleteFile,
          data: [file.id]
        }).finally(() => {
          resolve()
        })
      })
    },

    /**
     * 监听文件上传成功
     * @param {String} field:字段名
     * @param {String} count:文件数量/单个或多个
     */
    handleFileUploadSuccess (field, count) {
      if (count === 'single') {
        return (res, file, fileList) => {
          if (res.code === 1000) {
            const { data } = res
            fileList[0].id = data.id
            fileList[0].url = data.path
            this.companyInfoFormData[field] = fileList
          }
        }
      }
      return (res, file, fileList) => {
        if (res.code === 1000) {
          const { data } = res
          const index = fileList.findIndex(item => item === file)
          fileList[index].id = data.id
          fileList[index].url = data.url
          this.companyInfoFormData[field] = fileList
        }
      }
    },

    /**
     * 切换步骤
     */
    switchStep (step) {
      if (step === 2) {
        this.$refs.companyInfoForm.validate(valid => {
          if (valid) {
            this.stepActive = step
          } else {
            this.$message.error(this.$t('checkTips'))
          }
        })
      } else {
        this.stepActive = step
      }
    },

    /**
     * 企业名称变化后查询是否存在相同的企业名
     */
    handleCompanyNameChange (value) {
      if (!value) {
        return
      }
      const fieldObj = {
        companyName: value
      }
      this.validateDuplicateData(fieldObj).then(data => {
        if (data) {
          this.$confirm(this.$t('duplicateCompanyTip'), this.$t('duplicateWarning'), {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            showClose: false,
            showCancelButton: false,
            type: 'warning'
          }).then(() => {
            this.companyInfoFormData.companyName = ''
          })
        }
      })
    },

    handleCompanyUENChange (value) {
      /**
       * 企业UEN变化后查询是否存在相同的UEN
       */
      if (!value) {
        return
      }
      const fieldObj = {
        companyBusinessIncorporationNumber: value
      }
      this.validateDuplicateData(fieldObj).then(data => {
        if (data) {
          this.$confirm(this.$t('duplicateCompanyUENTip'), this.$t('duplicateWarning'), {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            showClose: false,
            showCancelButton: false,
            type: 'warning'
          }).then(() => {
            this.companyInfoFormData.businessIncorporationNumber = ''
          })
        }
      })
    },
    handleLicenseTypeChange () {
      if (this.companyInfoFormData?.licenseType) {
        const notRequiredIndex = this.companyInfoNotRequiredFields.findIndex(field => field === 'licenseNumber')
        if (notRequiredIndex !== -1) {
          this.companyInfoNotRequiredFields.splice(notRequiredIndex, 1)
          this.companyInfoNotRequiredFields.push('licenseNumber')
        }
        this.companyInfoRules.licenseNumber = [
          { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
        ]
      } else {
        this.companyInfoFormData.licenseNumber = ''
        const requiredIndex = this.companyInfoRequiredFields.findIndex(field => field === 'licenseNumber')
        if (requiredIndex !== -1) {
          this.companyInfoRequiredFields.splice(requiredIndex, 1)
          this.companyInfoNotRequiredFields.push('licenseNumber')
        }
        this.companyInfoRules.licenseNumber = [
          { required: false, trigger: 'blur' }
        ]
      }
    },
    reqRegister (data) {
      this.$request.post({
        url: this.$apis.registerCompany,
        data
      }).then(res => {
        if (res?.code === 1000) {
          this.$message.success(this.$t('registerSuccess'))
          this.registerSuccessVisible = true
          const { companyName, id, companyType } = res.data.organization
          if (companyType === COMPANY_TYPE.FINANCIAL_SERVICE_PROVIDER) {
            this.$refs.financerInfo.handleRegisteFinancer(true, companyName, id)
          }
          if (companyType === COMPANY_TYPE.TRADING) {
            this.$refs.financerInfo.handleRegisteFinancer(false, companyName, id)
          }
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },

    register () {
      /**
       * 提交注册数据
       * */
      this.submitLoading = true
      let isFormValid = true

      const invalidFormHandler = () => {
        this.$message.error('Please fill in the required items!')
        this.submitLoading = false
        isFormValid = false
      }


      this.$refs.companyInfoForm.validate(valid => {
        if (!valid) {
          invalidFormHandler()
          return
         }
      })

      if (!isFormValid) return;

      const data = {
        organization: this.$_.cloneDeep(this.companyInfoFormData),
        user: this.$_.cloneDeep(this.adminInfoFormData)
      }

      this.$refs.adminInfoForm.validate(valid => {
        if (!valid) {
          invalidFormHandler()
          return
        }

        this.fileFields.forEach(field => {
          const files = data.organization[field] || []
          data.organization[`${field}Ids`] = files.map(item => item.id)
          data.organization[field] = undefined
        })
      })

      if (!isFormValid) return;

      if (this.companyInfoFormData.companyType === COMPANY_TYPE.FINANCIAL_SERVICE_PROVIDER) {
        this.$refs.financerInfo.$refs.financerInfoForm.validate(valid => {
          if (!valid) {
            invalidFormHandler()
            return
          }
        })
      }

      if (!isFormValid) return;
      this.reqRegister(data)
    },
    handleCancelRegisterCompany () {
      const title = 'Cancel Company Registration'
      const body = 'Changes will be discarded. Continue?'
      this.$confirm(body, title, {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$router.push({ name: 'login' })
      }).catch(() => {})
    }
  },
  watch: {
    'companyInfoFormData.licenseType': {
      handler (newValue) {
        if (newValue) {
          // this.companyInfoRules.bdnLogo.push(
          //   { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
          // )
          this.companyInfoRules.craftOperatorLicences = []
          this.companyInfoRules.operatorLicences = []
          this.companyInfoRules.craftOperatorLicences.push(
            { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
          )
          this.companyInfoRules.operatorLicences.push(
            { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }
          )
        } else {
          this.companyInfoFormData.licenseNumber = ''
          // this.companyInfoRules.bdnLogo = []
          this.companyInfoRules = Object.assign({}, this.companyInfoRules, {
            craftOperatorLicences: [],
            operatorLicences: []
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "index";
</style>
